import { useEffect, useState } from 'react';
import useSWR from 'swr';
import { match } from 'ts-pattern';

import './safety-board-page.css';

import { useApiClient } from '../shared/use-api-client';
import { daysAgo, formatDateMmDdYyyy } from '../shared/date-utils';

type SafetyState = 'none' | 'green' | 'yellow' | 'red';

export function SafetyBoardPage() {
  const { getIncidents } = useApiClient();
  const { data: incidents, error } = useSWR('incidents', getIncidents, { refreshInterval: 30000 });

  const [currentState, setCurrentState] = useState<SafetyState>('none');
  let lastIncident = incidents && incidents[0];
  let lastInjury = incidents?.find(incident => incident.type === 'recorded');

  if (!lastIncident) {
    lastIncident = {
      type: 'reset',
      date: new Date(2018, 11, 5), // December 5th, 2018
    };
  }

  if (!lastInjury) {
    lastInjury = {
      type: 'reset',
      date: new Date(2018, 11, 5), // December 5th, 2018
    };
  }

  const timeDifference = new Date().getTime() - lastIncident.date.getTime();
  const dayBuffer = 14 * 24 * 60 * 60 * 1000; // 14 days in milliseconds

  useEffect(() => {
    if (incidents || error) {
      if (timeDifference > dayBuffer || lastIncident?.type === 'reset') {
        setCurrentState('green');
      } else if (lastIncident?.type === 'nearmiss') {
        setCurrentState('yellow');
      } else if (lastIncident?.type === 'recorded') {
        setCurrentState('red');
      }
    }
  }, [dayBuffer, error, incidents, lastIncident, timeDifference]);

  return (
    <div className="container">
      <div className="wrapper">
        <div className="safety-wrapper">
          <div className="traffic-light-wrapper">
            <div className="traffic-light" data-state={currentState}>
              <div className="light red" />
              <div className="light yellow" />
              <div className="light green" />
            </div>
          </div>

          <div className="message-wrapper-2">
            <div className="message-card-wrapper">
              <div data-state={currentState} className="message-wrapper">
                {match(currentState)
                  .with('green', () => (
                    <>
                      <div className="message one">
                        <p>
                          {lastInjury && daysAgo(new Date().getTime() - lastInjury.date.getTime())}
                        </p>
                      </div>
                      <div className="message two">
                        <p>without</p>
                      </div>
                      <div className="message three">
                        <p>injury</p>
                      </div>
                    </>
                  ))
                  .with('yellow', () => (
                    <>
                      <div className="message one">
                        <p>Near miss</p>
                      </div>
                      <div className="message two">
                        <p>incident on</p>
                      </div>
                      <div className="message three">
                        <p>{lastIncident && formatDateMmDdYyyy(lastIncident.date)}</p>
                      </div>
                    </>
                  ))
                  .with('red', () => (
                    <>
                      <div className="message one">
                        <p>Recordable</p>
                      </div>
                      <div className="message two">
                        <p>injury on</p>
                      </div>
                      <div className="message three">
                        <p>{lastIncident && formatDateMmDdYyyy(lastIncident.date)}</p>
                      </div>
                    </>
                  ))
                  .otherwise(() => null)}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
