import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import useSWR from 'swr';

import {
  Box,
  Button,
  Icon,
  IconButton,
  Modal,
  Table,
  TableCell,
  TableHeading,
  Text,
  ToggleButton,
  ToggleButtonGroup,
} from '@nike/eds';

import { useApiClient, useAuthApiClient } from '../../../shared/use-api-client';
import { formatDate } from '../../../shared/date-utils';

export function IncidentsIndexPage() {
  const navigate = useNavigate();
  const { getIncidents } = useApiClient();
  const { deleteIncident } = useAuthApiClient();

  const { data: incidents, mutate } = useSWR('incidents', getIncidents);
  const [filter, setFilter] = useState('all');
  const [filteredIncidents, setFilteredIncidents] = useState(incidents || []);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [incidentId, setIncidentId] = useState<string | undefined>();

  const onDelete = async () => {
    if (incidentId) {
      mutate(async () => {
        await deleteIncident(incidentId);
        return undefined;
      });

      setDeleteModalOpen(false);
    }
  };

  useEffect(() => {
    if (incidents) {
      setFilteredIncidents(incidents);
    }
  }, [incidents]);

  return (
    <Box>
      <Box className="eds-flex eds-flex--align-items-center eds-flex--justify-content-space-between eds-spacing--mb-24">
        <Text font="title-3">Incidents</Text>

        <Button to="incidents/new" as={Link}>
          Record Incident
        </Button>
      </Box>

      <ToggleButtonGroup size="small" className="eds-spacing--mb-24">
        <ToggleButton
          onClick={() => {
            setFilter('all');
            setFilteredIncidents(incidents || []);
          }}
          active={filter === 'all'}
          title="All"
        >
          All
        </ToggleButton>

        <ToggleButton
          onClick={() => {
            setFilter('reset');
            setFilteredIncidents(incidents?.filter(incident => incident.type === 'reset') || []);
          }}
          active={filter === 'reset'}
          title="Reset"
        >
          Reset
        </ToggleButton>

        <ToggleButton
          onClick={() => {
            setFilter('nearmiss');
            setFilteredIncidents(incidents?.filter(incident => incident.type === 'nearmiss') || []);
          }}
          active={filter === 'nearmiss'}
          title="Near Miss"
        >
          Near Miss
        </ToggleButton>

        <ToggleButton
          onClick={() => {
            setFilter('recordable');
            setFilteredIncidents(incidents?.filter(incident => incident.type === 'recorded') || []);
          }}
          active={filter === 'recordable'}
          title="Recordable"
        >
          Recordable
        </ToggleButton>
      </ToggleButtonGroup>

      <Table>
        <thead>
          <tr>
            <TableHeading>Date</TableHeading>
            <TableHeading>Type</TableHeading>
            <TableHeading>Reported On</TableHeading>
            <TableHeading>Description</TableHeading>
            <TableHeading>Actions</TableHeading>
          </tr>
        </thead>

        <tbody>
          {filteredIncidents.map(incident => (
            <tr key={incident.id}>
              <TableCell>{formatDate(incident.date)}</TableCell>
              <TableCell>{incident.type}</TableCell>
              <TableCell>{incident.createdAt?.toLocaleString()}</TableCell>
              <TableCell>{incident.description}</TableCell>

              <TableCell>
                <IconButton
                  icon="Edit"
                  label="Edit"
                  variant="secondary"
                  onClick={() => navigate(`incidents/${incident.id}/edit`)}
                />
                <IconButton
                  icon="Delete"
                  label="Delete"
                  variant="secondary"
                  onClick={() => {
                    setDeleteModalOpen(true);
                    setIncidentId(incident.id);
                  }}
                />
              </TableCell>
            </tr>
          ))}
        </tbody>
      </Table>

      <Modal
        isOpen={deleteModalOpen}
        onDismiss={() => setDeleteModalOpen(false)}
        headerSlot={<Text font="title-3">Confirm Delete</Text>}
        footerSlot={
          <Button
            onClick={onDelete}
            variant="secondary"
            beforeSlot={<Icon name="Delete" size="m" enableFocus />}
          >
            Delete
          </Button>
        }
      >
        <Text>Are you sure you want to delete?</Text>
      </Modal>
    </Box>
  );
}
