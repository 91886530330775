import useSWR from 'swr';
import { match, P } from 'ts-pattern';
import { useOktaAuth } from '@okta/okta-react';

/**
 * React hook to search for okta users with a startsWith string
 * It only calls the API when startsWith has a minumum of 3 characters
 * @param startsWith
 * @param accessToken
 * @returns
 */
export function useOktaUsers(url: string, startsWith: string) {
  const { oktaAuth } = useOktaAuth();

  const accessToken = oktaAuth.getAccessToken();
  if (!accessToken) {
    throw new Error('Error retrieving access token');
  }

  const fetcher = async () => {
    const response = await fetch(`${url}/users?startsWith=${startsWith}`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });

    if (!response.ok) {
      throw await response.json();
    }

    const oktaUsers = await response.json();
    return oktaUsers;
  };

  // only call fetch users when the string has a minimum 3 characters, as it's required by the API
  const shouldFetch = startsWith.length >= 3;

  const { data, error } = useSWR(shouldFetch ? ['oktaUsers', startsWith] : null, fetcher);

  const errorMessage = match(error)
    .with({ details: { statusCode: 403 } }, () => 'You do not have permission to view this asset')
    .with({ message: P.string }, e => e.message)
    .otherwise(() => undefined);

  return {
    oktaUsers: data || { items: [] },
    isUsersLoading: shouldFetch && !error && !data,
    isError: errorMessage,
  };
}
