import { useNavigate } from 'react-router-dom';
import { mutate } from 'swr';

import { Box, Text } from '@nike/eds';

import { useAuthApiClient } from '../../../shared/use-api-client';
import { Incident } from '../../../shared/types';
import { IncidentForm } from '../shared/incident-form';

export function IncidentsNewPage() {
  const navigate = useNavigate();
  const { createIncident } = useAuthApiClient();

  const saveIncident = async (incident: Incident) => {
    await createIncident(incident);
    mutate('incidents');

    navigate('/dashboard');
  };

  return (
    <Box style={{ width: '600px' }}>
      <Text font="title-3" className="eds-spacing--mb-24">
        Add an Incident
      </Text>

      <IncidentForm formSubmit={saveIncident} />
    </Box>
  );
}
