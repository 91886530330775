import { useOktaAuth } from '@okta/okta-react';
import axios from 'axios';

import { environment } from '../../environments/environment';
import { Incident } from './types';

export function useAuthApiClient() {
  const { oktaAuth } = useOktaAuth();

  const oktaToken = oktaAuth.getAccessToken();
  if (!oktaToken) {
    throw new Error('No okta token');
  }

  const axiosConfig = {
    headers: {
      Authorization: `Bearer ${oktaToken}`,
    },
  };

  return {
    createIncident: async (incident: Incident) => {
      try {
        const response = await axios.post(
          `${environment.apiBaseUrl}/incidents`,
          {
            incidentType: incident.type,
            incidentDate: incident.date,
            incidentDescription: incident.description,
          },
          axiosConfig
        );

        return response.data;
      } catch (err) {
        return Promise.reject(err);
      }
    },
    editIncident: async (incident: Incident) =>
      axios.put(
        `${environment.apiBaseUrl}/incidents/${incident.id}`,
        {
          incidentDate: incident.date,
          incidentType: incident.type,
          incidentDescription: incident.description,
          incidentCreatedAt: incident.createdAt,
        },
        axiosConfig
      ),
    deleteIncident: async (id: string) =>
      axios.delete(`${environment.apiBaseUrl}/incidents/${id}`, axiosConfig),
  };
}

export function useApiClient() {
  return {
    getIncidents: async (): Promise<Incident[]> => {
      const { data } = await axios.get(`${environment.apiBaseUrl}/incidents`);

      return data
        .map((incident: any) => ({
          id: incident.id,
          type: incident.incidentType,
          date: new Date(incident.incidentDate),
          createdAt: new Date(incident.createdAt),
          description: incident.incidentDescription,
        }))
        .sort(
          (incident1: Incident, incident2: Incident) =>
            incident2.date.getTime() - incident1.date.getTime()
        );
    },
  };
}
