import './intermission-page.css';

export function IntermissionPage() {
  return (
    <div className="intermission-container">
      <div className="intermission-header" />
      <div className="intermission-wrapper container">
        <div className="intermission-info urgent-wrapper">
          <h2>
            Do you have an <span className="red">Urgent</span> Injury?
          </h2>

          <p className="call">Call:</p>
          <div className="intermission-body">
            <ul>
              <li>APCC Building Security: 971-473-2650</li>
              <li>Campus Security: 503-671-3911</li>
              <li>Inform manager and report within 24 hours of an urgent injury</li>
            </ul>
          </div>
        </div>

        <div className="separator_line" />

        <div className="intermission-info nonurgent-wrapper">
          <h2>
            Do you have an <span className="yellow">Non-Urgent</span> Injury?
          </h2>

          <p className="call">Call: </p>
          <div className="intermission-body">
            <ul>
              <li>Nurse Line: 1-855-466-8179</li>
              <li>
                Do not go to the clinic without first reporting the injury to security, EHS, or your
                manager
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
