import { LoginCallback, Security } from '@okta/okta-react';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { Loading } from '@nike.innovation/ui-components';

import './global.css';

import { Route, Routes, useNavigate } from 'react-router-dom';
import { DashboardRoutes } from './dashboard/dashboard-routes';
import { IntermissionPage } from './intermission/intermission-page';
import { SafetyBoardPage } from './safety-board/safety-board-page';
import { environment } from '../environments/environment';

const oktaAuth = new OktaAuth({
  issuer: environment.issuer,
  clientId: environment.clientId,
  redirectUri: `${window.location.origin}/implicit/callback`,
  // scopes: ['openid', 'offline_access'],
  tokenManager: {
    autoRenew: true,
  },
  pkce: true,
});

export function App() {
  const navigate = useNavigate();

  const restoreOriginalUri = (_oktaAuth: OktaAuth, originalUri: string) => {
    navigate(toRelativeUrl(originalUri, window.location.origin), { replace: true });
  };

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <Routes>
        <Route path="/implicit/callback" element={<LoginCallback loadingElement={<Loading />} />} />

        <Route path="/" element={<SafetyBoardPage />} />
        <Route path="/intermission" element={<IntermissionPage />} />

        <Route path="/dashboard/*" element={<DashboardRoutes />} />
      </Routes>
    </Security>
  );
}
