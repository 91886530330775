import { Box, Spinner } from '@nike/eds';

export function Loading() {
  return (
    <>
      <Box height="32px" />
      <Box
        className="eds-spacing--px-56"
        maxWidth="1536px"
        ml="auto"
        mr="auto"
        style={{ margin: 'auto', position: 'relative' }}
      >
        <Spinner size="large" style={{ position: 'absolute', top: '100%', left: '50%' }} />
      </Box>
    </>
  );
}
