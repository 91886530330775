import { useState } from 'react';
import { Box, Button, Select, TextArea, TextField } from '@nike/eds';

import { formatDate } from '../../../shared/date-utils';
import { Incident } from '../../../shared/types';

export function IncidentForm({
  formSubmit,
  incident,
}: {
  formSubmit: (i: Incident) => Promise<void>;
  // eslint-disable-next-line react/require-default-props
  incident?: Incident;
}) {
  const today = formatDate(new Date());

  const [incidentType, setIncidentType] = useState<string | undefined>(incident?.type);
  const [incidentDate, setIncidentDate] = useState<Date | undefined>(incident?.date);
  const [incidentDescription, setIncidentDescription] = useState<string | undefined>(
    incident?.description
  );
  const [isPublishing, setIsPublishing] = useState(false);

  const submit = async () => {
    setIsPublishing(true);

    if (incidentDate && incidentType !== undefined) {
      await formSubmit({
        type: incidentType as Incident['type'],
        date: incidentDate,
        description: incidentDescription,
        createdAt: incident?.createdAt,
      });
    }

    setIsPublishing(false);
  };

  const incidentTypeOptions = [
    { value: 'nearmiss', label: 'Near Miss' },
    { value: 'recorded', label: 'Recordable Injury' },
    { value: 'reset', label: 'Reset' },
  ];

  return (
    <form onSubmit={submit}>
      <Box className="eds-flex eds-flex--direction-column eds-gap--24">
        <Select
          id="incidentType"
          options={incidentTypeOptions}
          label="Incident Type"
          onChange={newValue => {
            setIncidentType(newValue?.value);
          }}
          isSearchable={false}
          defaultValue={incidentTypeOptions.find(option => option.value === incidentType)}
          required
          errorMessage="Select an incident type"
        />

        <TextField
          id="incidentDate"
          label="Incident Date"
          type="date"
          defaultValue={incidentDate && formatDate(incidentDate)}
          required
          onChange={e => setIncidentDate(new Date(e.target.value.replace('-', '/')))}
          max={today}
        />

        <TextArea
          id="incidentDescription"
          label="Incident Description"
          value={incidentDescription}
          onChange={e => setIncidentDescription(e.target.value)}
          minRows={2}
        />

        <Button
          className="eds--dark"
          type="submit"
          disabled={isPublishing || !incidentType || !incidentDate}
          onClick={submit}
        >
          Submit
        </Button>
      </Box>
    </form>
  );
}
