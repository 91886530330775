import { useNavigate, useParams } from 'react-router-dom';
import useSWR, { mutate } from 'swr';

import { Box, Text } from '@nike/eds';

import { useApiClient, useAuthApiClient } from '../../../shared/use-api-client';
import { Incident } from '../../../shared/types';
import { IncidentForm } from '../shared/incident-form';

export function IncidentsEditPage() {
  const navigate = useNavigate();
  const { id } = useParams();
  const { editIncident } = useAuthApiClient();
  const { getIncidents } = useApiClient();
  const { data: incidents } = useSWR('incidents', getIncidents);
  const incident = incidents?.find(i => i.id === id);

  const saveIncident = async (i: Incident) => {
    if (id) {
      await editIncident({ ...i, id });
      mutate('incidents');

      navigate('/dashboard');
    }
  };

  return (
    <Box style={{ width: '600px' }}>
      <Text font="title-3" className="eds-spacing--mb-24">
        Edit Incident
      </Text>

      {incident && <IncidentForm formSubmit={saveIncident} incident={incident} />}
    </Box>
  );
}
