import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { Box, VerticalNavbar, Icon, setActiveValueInNavItems, NavItem, NavEvent } from '@nike/eds';

export function VerticalNav() {
  const navigate = useNavigate();

  const [topItems, setTopItems] = useState<NavItem[]>([
    {
      id: 'incidents',
      icon: 'AlertCircleFilled',
      label: 'Incidents',
      active: true,
    },
  ]);

  const onNavigate = (item: NavEvent) => {
    const newItems = setActiveValueInNavItems(item.id, topItems);
    if (newItems !== undefined) {
      setTopItems(newItems);
    }

    switch (item.id) {
      default:
        navigate('/dashboard');
    }
  };

  return (
    <Box>
      <VerticalNavbar
        appName="Composure"
        items={topItems}
        onNavigate={onNavigate}
        navLogoSlot={
          <Link to="/dashboard">
            <Icon name="NikeSwoosh" size="l" />
          </Link>
        }
      />
    </Box>
  );
}
