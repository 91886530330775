export const formatDate = (date: Date): string =>
  date.toLocaleDateString('fr-CA', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  });

export const daysAgo = (timeDifference: number) => {
  const timeDifferenceInDays = Math.floor(timeDifference / (24 * 60 * 60 * 1000));

  if (timeDifferenceInDays === 1) {
    return `${timeDifferenceInDays} day`;
  }

  return `${timeDifferenceInDays} days`;
};

export const formatDateMmDdYyyy = (date: Date): string => {
  const day = date.getDate();
  const month = date.getMonth() + 1;

  return `${month < 10 ? `0${month}` : month}-${day < 10 ? `0${day}` : day}-${date.getFullYear()}`;
};
