import { Route, Routes } from 'react-router-dom';
import { RequiresAuth } from '@nike.innovation/auth-guard';
import { Loading } from '@nike.innovation/ui-components';

import { Box, Text } from '@nike/eds';

import '@nike/eds/dist/index.css';
import '../../assets/fonts.css';

import { VerticalNav } from './vertical-nav';
import { IncidentsIndexPage } from './incidents/index/incidents-index-page';
import { IncidentsNewPage } from './incidents/new/incidents-new-page';
import { IncidentsEditPage } from './incidents/edit/incidents-edit-page';

export function DashboardRoutes() {
  return (
    <main>
      <Box className="eds-flex" style={{ height: '100vh' }}>
        <VerticalNav />

        <Box style={{ width: '100vw', overflowY: 'scroll' }}>
          <Box
            className="eds-spacing--px-56 eds-spacing--my-32"
            maxWidth="1536px"
            ml="auto"
            mr="auto"
          >
            <Text font="title-1" className="eds-spacing--mb-32">
              APCC Safety Dashboard
            </Text>

            <Routes>
              <Route path="" element={<RequiresAuth loadingElement={<Loading />} />}>
                <Route path="incidents/new" element={<IncidentsNewPage />} />
                <Route path="incidents/:id/edit" element={<IncidentsEditPage />} />

                <Route path="/" element={<IncidentsIndexPage />} />
              </Route>
            </Routes>
          </Box>
        </Box>
      </Box>
    </main>
  );
}
